import Alpine from 'alpinejs'
import axios from 'axios';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

window.Alpine = Alpine

// Getting hamburguer menu in small screens
const menu = document.getElementById("menu");

// Browser resize listener
window.addEventListener("resize", menuResize);

// Resize menu if user changing the width with responsive menu opened
function menuResize() {
    // First get the size from the window
    const window_size = window.innerWidth || document.body.clientWidth;
    if (window_size > 640) {
        menu.classList.remove("h-128");
    }
}

document.addEventListener("DOMContentLoaded", function () {
    const body = document.querySelector("body");
    body.classList.remove("opacity-0");
    body.classList.add("opacity-100");
    const photos = document.querySelectorAll("img");
    let delay = 0;
    photos.forEach((photo) => {
        setTimeout(() => {
            photo.classList.remove("opacity-0");
            photo.classList.add("opacity-100");
        }, delay);
        delay += 100;
    });

    Fancybox.bind("[data-fancybox]", {});
});

const contactForm = document.getElementById('contact-form');

if (contactForm) {
    contactForm.addEventListener('submit', function (event) {
        /**
         * This prevents the default behaviour of the browser submitting
         * the form so that we can handle things instead.
         */
        event.preventDefault();

        /**
         * This gets the element which the event handler was attached to.
         *
         * @see https://developer.mozilla.org/en-US/docs/Web/API/Event/currentTarget
         */
        const form = event.currentTarget;

        /**
          * Hidden field named action
          */
        const url = form.action.value;

        /**
         * This takes all the fields in the form and makes their values
         * available through a `FormData` instance.
         *
         * @see https://developer.mozilla.org/en-US/docs/Web/API/FormData
         */
        const formData = new FormData(form);

        axios({
            method: 'post',
            url: url,
            responseType: 'json',
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            data: formData,
        })
            .then(function (response) {
                // Refresh Recaptcha token 
                wheelformProcessRecaptchaCallback();

                // show processing
                let domLoader = contactForm.querySelector('svg#form-loader');
                let loader = domLoader.cloneNode(true);
                loader.classList.remove('hidden');
                contactForm.querySelector('button#contact-submit-btn').append(loader);

                // reset previous errors
                let prevErrors = contactForm.querySelectorAll('span.inputError');
                if (prevErrors != null) {
                    prevErrors.forEach(prevError => {
                        prevError.remove();
                    });
                }

                let success = response.data.success;
                let contactResponse = contactForm.querySelector('div#contact-response');

                if (success) {
                    // empty fields
                    contactForm.reset();

                    // show message
                    contactResponse.innerHTML = response.data.message;
                    contactResponse.classList.remove('hidden');

                    // disable submit button
                    contactForm.querySelector('button#contact-submit-btn').disabled = true;
                } else {
                    let errors = response.data.errors;
                    for (const key in errors) {
                        let inputNode = document.getElementById(key);
                        if (inputNode !== null) {
                            let errorSpan = document.createElement("span");
                            errorSpan.className = 'inputError';
                            errorSpan.innerHTML = errors[key];
                            inputNode.parentNode.append(errorSpan);
                        }
                    };
                }

                // hide processing
                setTimeout(() => {
                    loader.remove();
                }, '1000');

            })
            .catch(function (error) {
                console.log(error);
            });
    });
}

Alpine.start()